<!--  -->
<template>
  <div class="personal-page">
    <img src="@/assets/logo.png" class="wechat-logo" />
    <p class="tips">航都院OA</p>
    <button @click="wxAuth" class="button" v-if="!error">确认登录</button>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      error: null,
    };
  },
  name: "h5Login",
  components: {},
  computed: {},
  mounted() {
    this.onOpen();
  },

  methods: {
    ...mapActions("login", ["h5LoginAction"]),
    wxAuth() {
      const appid = process.env.VUE_APP_WXAPPID;
      const redirect_uri = encodeURIComponent(
        `${window.location.origin}${window.location.pathname}`
      );
      const state = "";
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
      window.location.replace(url);
    },
    onOpen() {
      let code = this.$route.query.code;
      if (!code) {
        return;
      }
      const postData = {
        code: this.$route.query.code,
      };
      this.h5LoginAction(postData).then((res) => {
        this.$router.replace("/");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.personal-page {
  display: flex;
  background: #fff;
  flex-direction: column;
  align-items: center;
  .wechat-logo {
    margin-top: 1rem;
    width: 4rem;
    height: 4rem;
  }
  .tips {
    color: #333;
    font-size: 0.32rem;
    font-weight: bold;
    margin-top: 0.2rem;
    line-height: 1.5;
  }
  .button {
    margin-top: 2rem;
    width: 6.9rem;
    height: 0.8rem;
    margin-bottom: 1rem;
    background: #0094EE;
    border-radius: 0.1rem;
    color: #fff;
    font-size: 0.3rem;
  }
  .success-tips {
    color: #999;
    font-size: 0.26rem;
    margin-top: 1rem;
    max-width: 6.9rem;
    line-height: 1.5;
  }
}
</style>
